<template>
    <div>
        <v-main fluid class="mt-n6">
            <div>
                <v-form ref="form"
                        v-model="valid"
                        :lazy-validation="lazy"
                        class="w-100">
                    <v-container fluid class="pa-8">
                        <dynoform @change="UpdateFormField($event, 'formdata')"
                                  @changeGrid="UpdateExtData($event, $event.tableName)"
                                  @click="FormButtonClicked($event)"
                                  :attributes="GetFormAttributes"
                                  @SearchInputChanged="SearchInputChanged($event, 'formdata')"
                                  @HeaderButtonClicked="HeaderButtonClickedDyno($event)"
                                  @changeAttribute="ChangeAttribute($event,'formdata')"
                                  :tabname="tab"
                                  :durum="durum"></dynoform>
                    </v-container>
                </v-form>
            </div>
            <v-footer class="grey lighten-3 pa-3" inset app padless>
                <v-progress-circular v-if="!isgformsdataloaded" :size="20" :width="2" rounded indeterminate color="primary" class="my-2 mr-2"></v-progress-circular>
                <v-toolbar-title v-if="!isgformsdataloaded" class="primary--text overline">{{ $t("IM_RetrievingData") }}</v-toolbar-title>
                <v-spacer></v-spacer>
                <v-btn v-for="(action, index) in GetFormAction"
                       :loading="actionBarWait"
                       @click="PageAction(action)"
                       depressed
                       class="mx-2 px-10 primary darken-1 white--text font-weight-medium text-capitalize"
                       :class="index == GetFormAction.length - 1 ? 'primary' : 'secondary'"
                       :key="action.action">
                    {{ $t(action.label) }}
                </v-btn>
            </v-footer>
            <v-dialog v-model="actionBarWait"
                      persistent>
                <v-card color="transparent"
                        dark class="py-5">
                    <v-card-text class="text-center py-5">
                        <v-progress-circular indeterminate
                                             :size="50"
                                             color="white"></v-progress-circular>
                    </v-card-text>
                </v-card>
            </v-dialog>

            <v-dialog persistent v-model="NeedConfirmationBeforeProceed" width="512">
                <v-card class="mx-auto pt-7 pb-2">
                    <alertIcon icon="warning"></alertIcon>
                    <v-card-title class="mb-0">
                        <p class="mx-auto headline pb-0 mb-0">{{$t("Warning")}}</p>
                    </v-card-title>
                    <v-card-text v-html="$t('NeedConfirmationBeforeProceed')">
                    </v-card-text>
                    <v-card-actions class="justify-content-center">
                        <v-btn text outlined v-for="(button, i) in ActionConfirmation" v-bind:key="i" v-bind:color="button.color" v-bind:class="button.textcolor" class="px-10 text-capitalize font-weight-medium d-flex align-center" v-on:click="PageAction_Confirm(button.onclick)">
                            {{$t(button.text)}}
                        </v-btn>
                    </v-card-actions>
                </v-card>
            </v-dialog>
        </v-main>
    </div>
</template>

<style>
    .v-dialog {
        box-shadow: none !important;
    }
</style>
<script>
    import Vue from 'vue';
    import Vuex from 'vuex';

    Vue.use(Vuex);

    var Tedarik_Ek_Belgeler = {
        tableName: 'Tedarik_Ek_Belgeler',
        label: 'AdditionalDocuments',
        columns: {
            description: {
                type: 'text',
                name: 'description',
                label: 'FileDescription',
                edit: '01234X',
                template: '',
                parameters: []
            },
            fileurl: {
                type: 'file',
                name: 'fileurl',
                label: 'FileSelection',
                edit: '01234X',
                template: '',
                headerhint: 'FileSelectionHint'
            },
            form:
            {
                type: 'hidden',
                label: '',
                edit: '',
                name: 'form',
                width: 0
            },
            formid: {
                type: 'hidden',
                label: '',
                edit: '',
                name: 'formid',
                width: 0
            },
            rowid: {
                type: 'hidden',
                label: '',
                edit: '',
                name: 'rowid',
                width: 0
            },
            id: {
                type: 'hidden',
                label: '',
                edit: '',
                name: 'id',
                width: 0
            },
        },
        attributes:
        {
            0:
            {
                id: { items: [], error: false, readonly: false, hidden: false },
                form: { items: [], error: false, readonly: false, hidden: false },
                formid: { items: [], error: false, readonly: false, hidden: false },
                rowid: { items: [], error: false, readonly: false, hidden: false },
                description: { items: [], error: false, readonly: false, hidden: false },
                fileurl: { items: [], error: false, readonly: false, hidden: false },
            }
        },
        extdatainfo: {
            id: 'id', //Parent table id
            ident: 'formid', //child id or foreinkey
            formField1: '',
            formFieldValue1: '',
            formField2: '',
            formFieldValue2: '',
            xtableName: '',
            queryName: 'Tedarik_Ek_Belgeler'
        },
        parameters: ['**'],
        data: [],
        loaded: false,
        loading: false
    };

    var dynoFormInfo = {
        tabs: [
            { order: 1, header: 'CompanyInformation' },
            /*{ order: 2, header: 'AdditionalDocuments' },*/
        ],
        TabAttributes: {
            "1": { show: true },
        },
        groups: [
            {
                tab: 1,
                order: 1,
                header: 'GeneralInformation'
            },
            {
                tab: 1,
                order: 2,
                header: 'ContactInformation'
            },
            {
                tab: 1,
                order: 3,
                header: 'AdditionalDocuments'
            },
        ],

        Columns: {
            id: {
                type: 'hidden',
                group: 1,
                sira: 0,
                name: 'id',
                label: 'ID',
                edit: '',
                dontCompare: true,
            },
            gformsid: {
                type: 'hidden',
                group: 1,
                sira: 0,
                name: 'gformsid',
                label: 'GFORMS ID',
                edit: '',
                dontCompare: true,
            },           
            firmaad: {
                type: 'text',
                name: 'firmaad',
                label: 'CompanyName',
                group: 1,
                sira: 10,
                col: '12',
                edit: '01234X',
            },
            yurtici: {
                type: 'select',
                name: 'yurtici',
                label: 'TurkeyBasedAbroad',
                group: 1,
                sira: 20,
                col: '3',
                edit: '01234X',
                options: [
                    //Filled At Created Method
                ],
                //validateMin: 1,
                validateMax: 1,
            },

            sahis: {
                type: 'select',
                name: 'sahis',
                label: 'IsSoleTrader',
                group: 1,
                sira: 30,
                col: '3',
                edit: '01234X',
                options: [
                    //Filled At Created Method
                ],
                validateMin: 1,
                validateMax: 1,
            },
            verginum: {
                type: 'text',
                name: 'verginum',
                label: 'VKNorTCKN',
                group: 1,
                sira: 40,
                col: '6',
                edit: '01234X',
                validateMin: 10,
                validateMax: 11,
            },
            ortaksektoradi: {
                type: 'auto',
                name: 'ortaksektoradi',
                label: 'Sektör',
                group: 1,
                sira: 51,
                col: '6',
                edit: '01234X',
                multiple: true,
                parameters: [],
                outputs: {
                    sektorid: 'sektorid',
                    ortaksektoradi: 'ortaksektoradi',
                },
                itemText: ['ortaksektoradi'],
                searchText: 'ortaksektoradi',
                validateMin: 1,
                minLength: 0,
                dontCompare: true,
            },
            sektorid: {
                type: 'hidden',
                group: 1,
                sira: 0,
                name: 'sektorid',
                label: '',
                edit: '',
                dontCompare: true,
            },
            sektorkodu: {
                type: 'hidden',
                group: 1,
                sira: 0,
                name: 'sektorkodu',
                label: '',
                edit: '',
                dontCompare: true,
            },
            sektoradi: {
                type: 'hidden',
                group: 1,
                sira: 0,
                name: 'sektoradi',
                label: '',
                edit: '',
                dontCompare: true,
            },
            vergilevhasifile: {
                type: 'file',
                name: 'vergilevhasifile',
                label: 'TaxBoard',
                group: 1,
                sira: 60,
                col: '6',
                edit: '01234X',
                validateMin: 1,
            },
            ticaretodasifile: {
                type: 'file',
                name: 'ticaretodasifile',
                label: 'ChamberCommerceDocument',
                group: 1,
                sira: 70,
                col: '6',
                edit: '01234X',
                validateMin: 1,
            },
            ticaretodasifiledysid: {
                type: 'hidden',
                name: 'ticaretodasifiledysid',
                group: 1,
                sira: 0,
                edit: '',
                dontCompare: true,
            },
            vergilevhasifiledysid: {
                type: 'hidden',
                name: 'vergilevhasifiledysid',
                group: 1,
                sira: 0,
                edit: '',
                dontCompare: true,
            },
            temsileyetkilikisilerfile: {
                type: 'file',
                name: 'temsileyetkilikisilerfile',
                label: 'AuthorizedToRepresent',
                group: 1,
                sira: 80,
                col: '6',
                edit: '01234X',
                validateMin: 1,
                messages: ['temsileyetkilikisilerfile_message_for_template_file'],
                customActionTitle: 'DownloadTemplate'
            },
            temsileyetkilikisilerfiledysid: {
                type: 'hidden',
                name: 'temsileyetkilikisilerfiledysid',
                group: 1,
                sira: 0,
                edit: '',
                dontCompare: true,
            },
            imzasirkulerifile: {
                type: 'file',
                name: 'imzasirkulerifile',
                label: 'AuthorizedSignaturesList',
                group: 1,
                sira: 90,
                col: '6',
                edit: '01234X',
                validateMin: 1,
            },
            imzasirkulerifiledysid: {
                type: 'hidden',
                name: 'imzasirkulerifiledysid',
                group: 1,
                sira: 0,
                edit: '',
                dontCompare: true,
            },
            yetkiliad: {
                type: 'text',
                name: 'yetkiliad',
                label: 'CompanyExecutiveName',
                group: 2,
                sira: 10,
                col: '3',
                edit: '01234X',
                validateMin: 1,
            },
            yetkilisoyad: {
                type: 'text',
                name: 'yetkilisoyad',
                label: 'CompanyExecutiveSurname',
                group: 2,
                sira: 11,
                col: '3',
                edit: '01234X',
                validateMin: 1,
            },
            email: {
                type: 'text',
                name: 'email',
                label: 'EmailAdress',
                group: 2,
                sira: 20,
                col: '6',
                edit: '01234X',
                validateMin: 1,
            },
            TelefonKodu: {
                type: 'auto',
                name: 'TelefonKodu',
                label: 'Phonecode',
                group: 2,
                sira: 29,
                col: '2',
                edit: '01234X',
                parameters: [],
                outputs: {
                    TelefonKodu: 'TelefonKodu'
                },
                itemText: ['en_ulke', 'TelefonKodu'],
                searchText: 'TelefonKodu',
                validateMin: 1,
                minLength: 0,
            },
            gsm: {
                type: 'text',
                name: 'gsm',
                label: 'Gsm',
                group: 2,
                sira: 30,
                col: '4',
                edit: '01234X',
                mask: 'phone',
                validateMin: 10,
                validateMax: 10,
            },
            tel: {
                type: 'text',
                name: 'tel',
                label: 'PhoneNo',
                group: 2,
                sira: 40,
                col: '3',
                edit: '01234X',
                mask: 'phone',
                validateMin: 10,
                validateMax: 10,
            },
            fax: {
                type: 'text',
                name: 'fax',
                label: 'FaxNo',
                group: 2,
                sira: 50,
                col: '3',
                edit: '01234X',
                mask: 'phone',
                validateMin: 10,
                validateMax: 10,
            },
            ulke: {
                type: 'auto',
                group: 2,
                sira: 60,
                col: '4',
                name: 'ulke',
                label: 'Country',
                edit: '01234X',
                parameters: [],
                outputs: {
                    ulke_tr: 'ulke_tr'
                },
                itemText: ['ulke'],
                searchText: 'ulke',
                validateMin: 3,
                minLength: 1,
                notAuthorizedRequest: true,
                fetchQueryname: 'tedarik_ulke'
            },
            ulke_tr: {
                type: 'hidden',
                name: 'ulke_tr',
                group: 1,
                sira: 0,
                edit: '',
                dontCompare: true,
            },
            il: {
                type: 'auto',
                group: 2,
                sira: 70,
                col: '4',
                name: 'il',
                label: 'City',
                edit: '01234X',
                parameters: ['*ulke'],
                outputs: {
                    sehirkodu: 'sehirkodu',
                },
                itemText: ['sehirkodu', 'il'],
                searchText: 'il',
                validateMin: 1,
                minLength: 0,
            },
            ilce: {
                type: 'auto',
                group: 2,
                sira: 80,
                col: '4',
                name: 'ilce',
                label: 'County',
                edit: '01234X',
                parameters: ['*ulke', '*il', '*sehirkodu'],
                outputs: {
                    ilce: 'ilce'
                },
                itemText: ['ilce'],
                searchText: 'ilce',
                validateMin: 1,
                minLength: 0,
            },
            adres: {
                type: 'textarea',
                group: 2,
                sira: 90,
                col: '12',
                name: 'adres',
                label: 'Address',
                edit: '01234X',
                validateMin: 1,
            },
            Tedarik_Ek_Belgeler: {
                type: 'grid',
                group: 3,
                sira: 10,
                name: 'Tedarik_Ek_Belgeler',
                label: 'AdditionalDocuments',
                edit: '01234X',
                col: '12',
                showselect: true,
                buttons: [
                    //{ text: 'Sil', color: 'red darken-2 white--text', icon: 'trash-can-outline', tooltip: 'Banka bilgilerinin başında bulunan kutucukları işaretleyerek istediğiniz hesapları silebilirsiniz.' }
                ],
                dontCompare: true,
                sort: 'disable',
                showexcelexport: false,
                hiddenlabel: true,
            },
        },
        form: 'tedarik',
        formid: 0,
        formdata: {
            id: 0,
            gformsid: 0,
            state: '0',
            firmaad: '',
            yurtici: '',
            sahis: '',
            verginum: '',
            sektoradi: '',
            ortaksektoradi: '',
            sektorid: '',
            sektorkodu: '',
            ticaretodasifile: '',
            vergilevhasifile: '',
            imzasirkulerifile: '',
            temsileyetkilikisilerfile: '',
            TelefonKodu: '',
            gsm: '',
            tel: '',
            fax: '',
            email: '',
            yetkiliad: '',
            yetkilisoyad: '',
            ulke: '',
            il: '',
            sehirkodu: '',
            ilce: '',
            adres: ''
        },
        viewName: 'tumu',
        viewparameter: '',

        files: ['ticaretodasifile', 'vergilevhasifile', 'imzasirkulerifile', 'temsileyetkilikisilerfile'],
        extdata: [Tedarik_Ek_Belgeler],
        actions: ['Kaydet'],
        parameters: [],
        attributes: {}
    };

    import { mapGetters } from 'vuex';
    import dynoform from '@/components/DynoForm.vue';
    import grid from '@/components/grid.vue';
    import leftmenu from '@/components/leftmenu.vue';
    import kview from '@/components/view.vue';
    import axios from 'axios';
    import _ from 'lodash';
    import 'material-design-icons-iconfont/dist/material-design-icons.css';
    import { FormMixIn } from '@/MixIns/FormMixins';
    import store from '@/store';
    import moment from 'moment';

    import { all } from 'core-js/fn/promise';

    import Utilities from '@/Utilities.js'

    import showdialog from '@/components/ShowDialog.vue';
    Vue.component('showdialog', showdialog);

    function ad_AE(payload) {
        /*alert('AE')*/
    }

    export default {
        name: 'bilgilerim',
        mixins: [FormMixIn],
        components: {
            dynoform,
            grid,
            leftmenu,
            kview
        },
        props: {
            //form: String,
            formid: [Number, String],
            view: { type: String, default: 'tumu' },
            drawer: { type: Boolean, default: true },
            single: { type: Boolean, default: false },
        },
        data: function () {
            return {
                NeedConfirmationBeforeProceed: false,
                ActionConfirmation: [
                    { text: 'Yes', color: 'green', textcolor: 'white--text', onclick: { action: 'Update', label: '' } },
                    { text: 'No', color: 'red darken-2', textcolor: 'white--text', onclick: { action: 'CANCEL', label: '' } },
                ],
                valid: false,
                lazy: true,
                viewname: 'tumu',
                form: 'tedarik',
                alert: false,
                alertMessage: '',
                actionResultDialog: false,
                validateError: '',
                username: '',
                formname: 'tedarik',
                depouser: false,
                accountuser: false,
                propertyeditstates: '',
                notification: false,
                tab: 'Tab-1',
                gformsdata: Object,
                isgformsdataloaded: false,
                durum: '',
                draftfile: 'portal_73a65e1f-d89a-4fad-8cf7-70fd2cca9b8f__IFC_Doc.pdf'
            };
        },
        computed: {
            GetState() {
                return this.$store.getters.GetFormState;
            },
            FormLoadingStatus: {
                get: function () {
                    return this.GetFormLoadingStatus;
                }
            }
        },
        methods: {
            SetGFormsData: function () {
                var formdata = this.GetFormData;
                Utilities.log("\\\\", formdata["gformsid"].toString().trim());
                var payload = {};
                payload.action = "GetTedarikData"
                payload.data = [];
                this.$store.dispatch("RunTime", payload)
                    .then(response => {
                        Utilities.log("Runtime  success result:", response);
                        this.gformsdata = response.data.runTimeResult[0];
                        this.durum = this.gformsdata["durumx"];
                        this.compareWithGFormsData(this.gformsdata);
                        this.isgformsdataloaded = true;
                    })
                    .catch(err => {
                        Utilities.log("Runtime erroro result:", err);
                    })

            },
            FormLoaded: function () {
                var columns = this.GetFormColumns;
                var formdata = this.GetFormData;
                var attributes = this.GetFormAttributes;

                this.SetFieldAttribute('temsileyetkilikisilerfile', 'hide', parseFloat(formdata['sahis']) == 1);
                this.SetFieldAttribute('imzasirkulerifile', 'hide', parseFloat(formdata['sahis']) == 1);
            },

            Degistir: function (fieldname) {
                var payload = {};
                payload.attributeName = "approved";
                payload.fieldName = fieldname;
                payload.value = false;
                payload.tableName = "formdata";

                this.$store.commit('SetFieldAttributes', payload);
            },

            PageAction: function (action) {
                var err = this.ValidateForm();

                if (err.length > 0) {
                    var messages = {
                        messageBody: err.join('<br/>'),
                        infoBody: '',
                        messageType: 'W'
                    };
                    this.$root.$emit('setUpMessage', messages);

                    return;
                }

                this.NeedConfirmationBeforeProceed = true;
            },
            PageAction_Confirm(action) {
                this.NeedConfirmationBeforeProceed = false;

                if (action.action == 'CANCEL') return;

                this.actionBarWait = true;
                var self = this;

                var payload = {
                    form: 'tedarik',
                    formid: 0,
                    action: action,
                    viewName: ''
                };

                this.$store
                    .dispatch('SendFormAction', payload)
                    .then(response => {
                        self.actionBarWait = false;

                        if (response.data.errorCode == '0') {
                            var messages = {
                                messageBody: this.$root.$i18n.t('TransactionSuccessful'),
                                infoBody: '',
                                messageType: 'B'
                            };
                            this.$root.$emit('setUpMessage', messages);

                            this.$router.push('/Anasayfa');
                        }
                        else {
                            var messages = {
                                messageBody: response.data.errorMessage,
                                infoBody: '',
                                messageType: 'E'
                            };

                            this.$root.$emit('setUpMessage', messages);
                        }

                    })
                    .catch(error => {
                        Utilities.log('FormAction catch error:', error);
                        this.actionBarWait = false;
                        var messages = {
                            messageBody: error,
                            infoBody: '',
                            messageType: 'E'
                        };
                        this.$root.$emit('setUpMessage', messages);
                    });
            },
            FormButtonClicked(col) {
                var name = col.name;

                if (name == 'temsileyetkilikisilerfile') {
                    console.log('a', Utilities.GetCurrentLanguage());
                    let filename = this.$root.$i18n.t('temsileyetkilikisilersablon' + (Utilities.GetCurrentLanguage() == "tr" ? 'yurtici' : 'yurtdisi') + 'FileName');

                    window.open(`${this.GetHostUrl}assets/docs/${filename}`, 'blank')
                    return;
                }
            },
            RowClicked: function (rowdata, table) {
                Utilities.log('clicked on table:', table, ' Row:', rowdata);
            },
            RowButtonClicked: function (payload, tableName) {
                Utilities.log(
                    ' Inventory Items RowButtonClicked',
                    payload,
                    ' Table:',
                    tableName
                );
                var button = payload.button.label;
                var id = payload.item.rowid;
                Utilities.log('Button on row:' + id + ' action:' + button);
                if (button == 'Sil')
                    this.$store.commit('RemoveRowFromExtData', {
                        tableName: tableName,
                        rowid: id
                    });
            },
            HeaderButtonClickedDyno(payload) {
                var tableName = payload.button.split(':::')[0];
                var buttonName = payload.button.split(':::')[1];
                var list = payload.list;

                Utilities.log('list >>>>> ', list);

                if (buttonName == 'Sil') {
                    for (var i = 0; i < list.length; i++) {
                        this.$store.commit('RemoveRowFromExtData', {
                            tableName: tableName,
                            rowid: list[i].rowid
                        });
                    }
                    this.message2 = { action: 'deselect', value: 0, random: Utilities.GetRandomNumber() };
                }
            },
            HeaderButtonClickedasdasd(payload, tableName) {
                var button = payload.button;
                var list = payload.list;
                if (button == 'Ekle->' && tableName == 'Inventory_Asset_data') {
                    for (var i = 0; i < list.length; i++) {
                        var parms = {
                            button: {
                                label: 'Ekle'
                            },
                            item: list[i]
                        };

                        var res = this.RowButtonClicked(parms, 'Inventory_Asset_data');
                        if (!res) return;
                    }
                    this.message1 = { action: 'deselect', value: 0, random: Utilities.GetRandomNumber() };
                } else if (button == '<-Çıkart' && tableName == 'AssetList') {
                    for (var i = 0; i < list.length; i++) {
                        this.$store.commit('RemoveRowFromExtData', {
                            tableName: tableName,
                            rowid: list[i].rowid
                        });
                    }
                    this.message2 = { action: 'deselect', value: 0, random: Utilities.GetRandomNumber() };
                }
            },
            ChangeAttribute(e, tableName) {
                var payload = {};
                payload.attributeName = e.attributeName;
                payload.fieldName = e.column.name;
                payload.value = e.value;
                payload.tableName = tableName;
                Utilities.log("FORM Emit changeAttribute", payload)
                this.$store.commit('SetFieldAttributes', payload);
            },
            IsLegitPhoneNumber: function (value) {
                return /^\d+$/.test(value);
            },
            CustomValidate: function (value, column) {
                if (column.name == 'gsm' || column.name == 'tel' || column.name == 'fax') {
                    var yurticiOryurtdisi = this.GetFormData['yurtici'].toString().trim();
                    var TelefonKodu = this.GetFormData['TelefonKodu'].toString().trim();

                    if (TelefonKodu == '90') {
                        column.validateMax = 10;
                        column.validateMin = 10;
                    }
                    else {
                        column.validateMax = 20;
                        column.validateMin = 1;
                    }

                    if (column.name == 'tel' || column.name == 'fax') {
                        if (value.length > 0) {
                            return this.ValidateField(value, column);
                        }
                    }
                    else {
                        var Match = value.match(/\d/g);

                        value = (Match != null) ? Match.join("") : "";

                        if (value != "" && TelefonKodu == '90') {
                            if (value.startsWith("90")) {
                                value = value.substring(2);
                            }

                            if (value.startsWith("0")) {
                                value = value.substring(1);
                            }
                        }

                        return this.ValidateField(value, column);
                    }
                }
                //else if (column.name == 'TelefonKodu')
                //{
                //    var yurticiOryurtdisi = this.GetFormData['yurtici'].toString().trim();

                //    if (yurticiOryurtdisi == '1')
                //    {
                //        if (value != '90')
                //        {
                //            return 'WarnMustChooseTurkeyPhoneCode'
                //        }
                //    }
                //}
                else {
                    return this.ValidateField(value, column);
                }

                return '';
            },
            ValidateForm() {
                this.ClearFieldErrors();
                this.notification = false;
                var errors = [];

                var columns = this.GetFormColumns;
                var formdata = this.GetFormData;
                var attributes = this.GetFormAttributes;
                //var GetExdataColumns = this.GetExdataColumns('Tedarik_Firma_Hesap');
                //var GetExdataItems = this.GetExdataItems('Tedarik_Firma_Hesap');
                //var ExtDataRowLen = GetExdataItems.length;

                Utilities.log("formdata ::::: ", formdata);
                Utilities.log("columns ::::: ", columns);
                //Utilities.log("GetExdataColumns ::::: ", GetExdataColumns);
                //Utilities.log("GetExdataItems ::::: ", GetExdataItems);

                var needsCustomValidationFields = ['gsm', 'tel', 'fax', 'vergilevhasifile', 'imzasirkulerifile', 'temsileyetkilikisilerfile'];
                var escapeFields = ['id', 'verginum', 'gformsid', 'ticaretodasifile', 'ticaretodasifiledysid', 'vergilevhasifiledysid', 'imzasirkulerifiledysid', 'temsileyetkilikisilerfiledysid'];

                if (parseFloat(formdata['sahis']) == 1) {
                    escapeFields.push('temsileyetkilikisilerfile');
                    escapeFields.push('imzasirkulerifile');
                }

                for (var c in columns) {
                    if (formdata[c] == null) formdata[c] = "";

                    var value = formdata[c].toString().trim();
                    var column = columns[c];
                    var attr = attributes[c];
                    var err = '';

                    if (!escapeFields.includes(column.name)) {
                        if (attr.disabled == false) {
                            if (needsCustomValidationFields.includes(column.name)) {
                                err = this.CustomValidate(value, column);
                            }
                            else {
                                err = this.ValidateField(value, column);
                            }

                            if (err != '') {
                                ////this.notification = true; //Sağ üstteki mavi zımbırtı açılmak istenirse diye bırakıldı.
                                errors.push(err);
                                this.SetFieldError(c);
                                this.tab = 'Tab-1';
                            }
                        }
                    }
                }

                var exdatas = dynoFormInfo.extdata;

                for (var j = 0; j < exdatas.length; j++) {
                    var exdata = exdatas[j];
                    var exdataColumns = this.GetExdataColumns(exdata.tableName);
                    var exdataItems = this.GetExdataItems(exdata.tableName);

                    for (var i = 0; i < exdataItems.length; i++) {
                        for (var col in exdataColumns) {
                            if (exdataItems[i][col] == undefined) exdataItems[i][col] = "";

                            var value = exdataItems[i][col].toString().trim();
                            var column = exdataColumns[col];
                            var err = this.ValidateField(value, column);

                            var id = exdataItems[i]['rowid'];

                            if (err != '') {
                                ////this.notification = true; //Sağ üstteki mavi zımbırtı açılmak istenirse diye bırakıldı.
                                errors.push(err);
                                this.SetExtDataFieldError(exdata.tableName, id, col);
                                this.tab = 'Tab-3';
                            }
                            else {
                                this.ClearExtDataFieldError(exdata.tableName, id, col);
                            }
                        }
                    }
                }

                this.validateError = errors;
                return errors;
            },

            alwaysDisabledFields: function () {
                this.SetFieldDisabled('yurtici');
                this.SetFieldDisabled('verginum');
                this.SetFieldDisabled('sahis');
                this.SetFieldDisabled('email');

                if (this.GetFormField('yurtici') == "1") {
                    this.ClearFieldDisabled('firmaad');
                }
                else {
                    this.SetFieldDisabled('firmaad');
                }


                let payload = {};

                if (this.GetFormField('ulke') == 'TÜRKİYE') {
                    this.ClearFieldDisabled('il');

                    if (this.GetFormField('il') == '') {
                        this.SetFieldDisabled('ilce');
                        payload.ilce = '';
                    }
                    else {
                        this.ClearFieldDisabled('ilce');
                    }
                }
                else {
                    this.SetFieldDisabled('il');
                    this.SetFieldDisabled('ilce');

                    payload.il = '';
                    payload.ilce = '';
                }

                this.$store.commit('SetFields', payload);
            },

            yurtici_Field_Changed: function () {
                if (this.GetFormField('yurtici') == '1') {
                    var payload = {};
                    payload.TelefonKodu = '90';
                    this.$store.commit('SetFields', payload);
                }
            },

            ulke_Field_Changed: function () {
                var payload = {};

                if (this.GetFormField('ulke') == 'TÜRKİYE') {
                    this.ClearFieldDisabled('il');
                    this.SetFieldDisabled('ilce');
                }
                else {
                    this.SetFieldDisabled('il');
                    this.SetFieldDisabled('ilce');
                }

                payload.il = '';
                payload.ilce = '';

                this.$store.commit('SetFields', payload);
            },

            il_Field_Changed: function () {
                var payload = {};

                if (this.GetFormField('il') == '') {
                    this.SetFieldDisabled('ilce');
                }
                else {
                    this.ClearFieldDisabled('ilce');
                }

                payload.ilce = '';

                this.$store.commit('SetFields', payload);
            },

            sektor_Field_Changed: function () {
                var payload = {};

                if (this.GetFormField('sektor') == '') {
                    this.SetFieldDisabled('altsektor');
                }
                else {
                    this.ClearFieldDisabled('altsektor');
                }

                payload.altsektor = '';

                this.$store.commit('SetFields', payload);
            },

            TelefonKodu_Field_Changed: function () {
                var payload = {};

                payload.gsm = '';

                this.$store.commit('SetFields', payload);
            },

            compareWithGFormsData: function (gformsdata) {
                var columns = this.GetFormColumns;
                var formdata = this.GetFormData;
                var gformdata = gformsdata;

                for (var c in columns) {
                    var original_c = c;
                    var column = columns[c];

                    if (column.type == "file") c = c + "dysid"; //dosya yüklemeleri dys üzerinden yapılıyor. o alanların isimleri sonlarına dysid

                    var portal_data = String(formdata[c]).trim();
                    if (portal_data == "null" || portal_data == "undefined") portal_data = "";
                    var form_data = String(gformdata[c]).trim();
                    if (form_data == "null" || form_data == "undefined") form_data = "";

                    if (c == "ulke")  // ülke için formsta TR isimler portalde EN isimler tutuluyor. o yüzden böyle bir ek kontrol gerekti
                        portal_data = String(formdata[c + "_tr"]).trim();

                    if (!column.dontCompare && form_data != portal_data) {
                        Utilities.log("column_name :::::", c);
                        Utilities.log("portal_data :::::", portal_data);
                        Utilities.log("form_data   :::::", form_data);
                        this.Degistir(original_c);
                    }
                }
            },
        },

        created: function () {
            var columns = dynoFormInfo.Columns;
            var attributes = {};
            for (var key in columns) {
                if (columns.hasOwnProperty(key)) {
                    attributes[key] = {
                        items: [],
                        readonly: false,
                        hide: '',
                        error: false,
                        disabled: false,
                        approved: true,
                        UploadStatus: 'NotMounted'
                    };
                }
            }
            dynoFormInfo.attributes = attributes;

            dynoFormInfo.Columns.yurtici.options = [
                { text: this.$root.$i18n.t('PleaseSelect'), value: '' },
                { text: this.$root.$i18n.t('Abroad'), value: '0' },
                { text: this.$root.$i18n.t('TurkeyBased'), value: '1' }
            ];

            dynoFormInfo.Columns.sahis.options = [
                { text: this.$root.$i18n.t('PleaseSelect'), value: '' },
                { text: this.$root.$i18n.t('No'), value: '0' },
                { text: this.$root.$i18n.t('Yes'), value: '1' }
            ];     
        },
        mounted: function () {
            var self = this;

            this.$store.commit('updateFormInfo', dynoFormInfo);
            this.$store.commit('SetFormData', dynoFormInfo.formdata);
            this.$store.commit('SetFormActions', [{ label: 'Güncelle', action: 'Update' }]);
            var payload = {};
            payload.action = "GetCompanyInfo"
            payload.data = [];

            this.$store.dispatch("RunTime", payload)
                .then(response => {
                    var companyInfo = response.data.runTimeResult[0];
                    this.$store.commit("SetFormLoadingStatus", false);
                    self.actionBarWait = false;

                    this.$store.commit('SetFields', companyInfo);

                    this.SetGFormsData();

                    this.alwaysDisabledFields();

                    this.FormLoaded();

                    var belgeler = {};
                    belgeler.tableName = 'Tedarik_Ek_Belgeler';
                    belgeler.data = response.data.runTimeResult[0].Tedarik_Ek_Belgeler;
                    belgeler.maxLen = 0;
                    this.$store.commit('SetExtdataData', belgeler);
                });
        }
    };
</script>
