var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    [
      _c(
        "v-main",
        { staticClass: "mt-n6", attrs: { fluid: "" } },
        [
          _c(
            "div",
            [
              _c(
                "v-form",
                {
                  ref: "form",
                  staticClass: "w-100",
                  attrs: { "lazy-validation": _vm.lazy },
                  model: {
                    value: _vm.valid,
                    callback: function ($$v) {
                      _vm.valid = $$v
                    },
                    expression: "valid",
                  },
                },
                [
                  _c(
                    "v-container",
                    { staticClass: "pa-8", attrs: { fluid: "" } },
                    [
                      _c("dynoform", {
                        attrs: {
                          attributes: _vm.GetFormAttributes,
                          tabname: _vm.tab,
                          durum: _vm.durum,
                        },
                        on: {
                          change: function ($event) {
                            return _vm.UpdateFormField($event, "formdata")
                          },
                          changeGrid: function ($event) {
                            return _vm.UpdateExtData($event, $event.tableName)
                          },
                          click: function ($event) {
                            return _vm.FormButtonClicked($event)
                          },
                          SearchInputChanged: function ($event) {
                            return _vm.SearchInputChanged($event, "formdata")
                          },
                          HeaderButtonClicked: function ($event) {
                            return _vm.HeaderButtonClickedDyno($event)
                          },
                          changeAttribute: function ($event) {
                            return _vm.ChangeAttribute($event, "formdata")
                          },
                        },
                      }),
                    ],
                    1
                  ),
                ],
                1
              ),
            ],
            1
          ),
          _c(
            "v-footer",
            {
              staticClass: "grey lighten-3 pa-3",
              attrs: { inset: "", app: "", padless: "" },
            },
            [
              !_vm.isgformsdataloaded
                ? _c("v-progress-circular", {
                    staticClass: "my-2 mr-2",
                    attrs: {
                      size: 20,
                      width: 2,
                      rounded: "",
                      indeterminate: "",
                      color: "primary",
                    },
                  })
                : _vm._e(),
              !_vm.isgformsdataloaded
                ? _c(
                    "v-toolbar-title",
                    { staticClass: "primary--text overline" },
                    [_vm._v(_vm._s(_vm.$t("IM_RetrievingData")))]
                  )
                : _vm._e(),
              _c("v-spacer"),
              _vm._l(_vm.GetFormAction, function (action, index) {
                return _c(
                  "v-btn",
                  {
                    key: action.action,
                    staticClass:
                      "mx-2 px-10 primary darken-1 white--text font-weight-medium text-capitalize",
                    class:
                      index == _vm.GetFormAction.length - 1
                        ? "primary"
                        : "secondary",
                    attrs: { loading: _vm.actionBarWait, depressed: "" },
                    on: {
                      click: function ($event) {
                        return _vm.PageAction(action)
                      },
                    },
                  },
                  [_vm._v(" " + _vm._s(_vm.$t(action.label)) + " ")]
                )
              }),
            ],
            2
          ),
          _c(
            "v-dialog",
            {
              attrs: { persistent: "" },
              model: {
                value: _vm.actionBarWait,
                callback: function ($$v) {
                  _vm.actionBarWait = $$v
                },
                expression: "actionBarWait",
              },
            },
            [
              _c(
                "v-card",
                {
                  staticClass: "py-5",
                  attrs: { color: "transparent", dark: "" },
                },
                [
                  _c(
                    "v-card-text",
                    { staticClass: "text-center py-5" },
                    [
                      _c("v-progress-circular", {
                        attrs: { indeterminate: "", size: 50, color: "white" },
                      }),
                    ],
                    1
                  ),
                ],
                1
              ),
            ],
            1
          ),
          _c(
            "v-dialog",
            {
              attrs: { persistent: "", width: "512" },
              model: {
                value: _vm.NeedConfirmationBeforeProceed,
                callback: function ($$v) {
                  _vm.NeedConfirmationBeforeProceed = $$v
                },
                expression: "NeedConfirmationBeforeProceed",
              },
            },
            [
              _c(
                "v-card",
                { staticClass: "mx-auto pt-7 pb-2" },
                [
                  _c("alertIcon", { attrs: { icon: "warning" } }),
                  _c("v-card-title", { staticClass: "mb-0" }, [
                    _c("p", { staticClass: "mx-auto headline pb-0 mb-0" }, [
                      _vm._v(_vm._s(_vm.$t("Warning"))),
                    ]),
                  ]),
                  _c("v-card-text", {
                    domProps: {
                      innerHTML: _vm._s(
                        _vm.$t("NeedConfirmationBeforeProceed")
                      ),
                    },
                  }),
                  _c(
                    "v-card-actions",
                    { staticClass: "justify-content-center" },
                    _vm._l(_vm.ActionConfirmation, function (button, i) {
                      return _c(
                        "v-btn",
                        {
                          key: i,
                          staticClass:
                            "px-10 text-capitalize font-weight-medium d-flex align-center",
                          class: button.textcolor,
                          attrs: {
                            text: "",
                            outlined: "",
                            color: button.color,
                          },
                          on: {
                            click: function ($event) {
                              return _vm.PageAction_Confirm(button.onclick)
                            },
                          },
                        },
                        [_vm._v(" " + _vm._s(_vm.$t(button.text)) + " ")]
                      )
                    }),
                    1
                  ),
                ],
                1
              ),
            ],
            1
          ),
        ],
        1
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }